(function ($) {
  $(document).ready(function(){
    $('.eyecatcher .owl-carousel').owlCarousel({
        // slider settings
        autoplay: true,
        autoplayHoverPause: true,
        loop: true,
        items: 1,

        // slide speed
        autoplayTimeout: 7000,
        smartSpeed: 500,
        
        // animation
        animateIn: 'fadeIn',
        animateOut: 'fadeOut',

        // navigation
        nav: true,
        dots: true,
        navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],

        // drag options
        mouseDrag: false
    })

    $(".home .slider").owlCarousel({
      // slider settings
      autoplay: false,
      autoplayHoverPause: true,
      loop: true,
      items: 4,
      margin: 20,

      // slide speed
      autoplayTimeout: 7000,
      smartSpeed: 500,

      // navigation
      nav: false,
      navContainer: false,

      dots: false,

      // drag options
      mouseDrag: true,

      // responsive
      responsive: {
          0: {
              items: 2
          },
          576: {
              items: 2
          },
          768: {
              items: 3
          },
          992: {
              items: 4
          },
          1200: {
              items: 6
          },
          1400: {
            items: 8
        }
      }
    });

    $('.accommodation_detail .link[href^="#"]').click(function(e) {
        e.preventDefault();

        $tommyTop = $('.accommodation_detail .tommy').offset().top;

        $('html, body').animate({
            scrollTop: $tommyTop
        }, 2000);
    });
    
    var windowWidth = $(window).width();
    if (windowWidth > 1200) {
        $mini_sab_height = $('.main').offset().top;

        $(window).scroll(function() {
            if ($(window).scrollTop() > $mini_sab_height) {
                $('body').addClass('sticky-sab');
            }
            else {
                $('body').removeClass('sticky-sab');
            }
        });

        $(window).scroll(function(){
            if ($(window).scrollTop() > 0) {
                $('body').addClass('sticky');
                $('.header').addClass('sticky');
            }
            else {
                $('body').removeClass('sticky');
                $('.header').removeClass('sticky');
            }
        });

    }

    // map - open/close marker info
    // $(document).on('click', '.section_map .map_logo', function() {
    //     $('.section_map .caption').toggleClass('visible');
    // });
  });
})(jQuery);
